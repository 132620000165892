let feature6 = {
  nm: "Comp 1",
  ddd: 0,
  h: 500,
  w: 750,
  meta: { g: "@lottiefiles/toolkit-js 0.21.3" },
  layers: [
    {
      ty: 4,
      nm: "Group 1",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [351.348, 224.812, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [351.348, 224.812, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -3.879],
                        [3.879, 0],
                        [0, 3.879],
                        [-3.879, 0],
                      ],
                      o: [
                        [0, 3.879],
                        [-3.879, 0],
                        [0, -3.879],
                        [3.879, 0],
                      ],
                      v: [
                        [7.023, 0],
                        [0, 7.023],
                        [-7.023, 0],
                        [0, -7.023],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [45.033, 235.31], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [45.033, 235.31], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 93,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [130, 130],
                    t: 106,
                  },
                  { s: [100, 100], t: 113.000004602584 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [45.033, 235.31], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -6.141],
                        [6.211, 0],
                        [0, 6.141],
                        [-6.212, 0],
                      ],
                      o: [
                        [0, 6.141],
                        [-6.212, 0],
                        [0, -6.141],
                        [6.211, 0],
                      ],
                      v: [
                        [11.247, 0],
                        [0, 11.119],
                        [-11.247, 0],
                        [0, -11.119],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [653.439, 415.625], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [653.439, 415.625], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 83,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [130, 130],
                    t: 96,
                  },
                  { s: [100, 100], t: 103.000004195276 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [653.439, 415.625], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -4.553],
                        [4.605, 0],
                        [0, 4.553],
                        [-4.605, 0],
                      ],
                      o: [
                        [0, 4.553],
                        [-4.605, 0],
                        [0, -4.553],
                        [4.605, 0],
                      ],
                      v: [
                        [8.338, 0],
                        [0, 8.244],
                        [-8.338, 0],
                        [0, -8.244],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [608.166, 235.31], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [608.166, 235.31], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 73,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [130, 130],
                    t: 86,
                  },
                  { s: [100, 100], t: 93.0000037879676 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [608.166, 235.31], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          ix: 4,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -9.089],
                        [9.089, 0],
                        [0, 9.089],
                        [-9.089, 0],
                      ],
                      o: [
                        [0, 9.089],
                        [-9.089, 0],
                        [0, -9.089],
                        [9.089, 0],
                      ],
                      v: [
                        [16.456, 0],
                        [0, 16.456],
                        [-16.456, 0],
                        [0, -16.456],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [455.189, 39.337], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [455.189, 39.337], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 63,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [130, 130],
                    t: 76,
                  },
                  { s: [100, 100], t: 83.0000033806593 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [455.189, 39.337], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          ix: 5,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -5.652],
                        [5.652, 0],
                        [0, 5.652],
                        [-5.652, 0],
                      ],
                      o: [
                        [0, 5.652],
                        [-5.652, 0],
                        [0, -5.652],
                        [5.652, 0],
                      ],
                      v: [
                        [10.233, 0],
                        [0, 10.233],
                        [-10.233, 0],
                        [0, -10.233],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [178.952, 137.141], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [178.952, 137.141], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 53,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [130, 130],
                    t: 66,
                  },
                  { s: [100, 100], t: 73.000002973351 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [178.952, 137.141], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          ix: 6,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -4.613],
                        [4.613, 0],
                        [0, 4.613],
                        [-4.613, 0],
                      ],
                      o: [
                        [0, 4.613],
                        [-4.613, 0],
                        [0, -4.613],
                        [4.613, 0],
                      ],
                      v: [
                        [8.353, 0],
                        [0, 8.353],
                        [-8.353, 0],
                        [0, -8.353],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [107.073, 408.328], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [107.073, 408.328], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 43,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [130, 130],
                    t: 56,
                  },
                  { s: [100, 100], t: 63.0000025660426 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [107.073, 408.328], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          ix: 7,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 7",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -4.613],
                        [4.613, 0],
                        [0, 4.613],
                        [-4.613, 0],
                      ],
                      o: [
                        [0, 4.613],
                        [-4.613, 0],
                        [0, -4.613],
                        [4.613, 0],
                      ],
                      v: [
                        [8.353, 0],
                        [0, 8.353],
                        [-8.353, 0],
                        [0, -8.353],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [510.275, 75.983], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [510.275, 75.983], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 33,
                  },
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [130, 130],
                    t: 46,
                  },
                  { s: [100, 100], t: 53.0000021587343 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [510.275, 75.983], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 1,
    },
    {
      ty: 4,
      nm: "{...} Outlines",
      sr: 1,
      st: 56.0000022809268,
      op: 356.000014500177,
      ip: 56.0000022809268,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [16.379, -5.755, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 86,
            },
            { s: [100, 100, 100], t: 99.0000040323527 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [679.441, 246.835, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [255], t: 86 },
            { s: [0], t: 99.0000040323527 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "{",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "{",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 1.449],
                    [0, 0],
                    [-1.029, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -2.415],
                    [0, 0],
                    [0.567, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.672],
                    [0, 0],
                    [-2.625, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0.987],
                    [0, 0],
                    [0.966, 0.294],
                  ],
                  o: [
                    [0, 0],
                    [0, -0.987],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.625, 0],
                    [0, 0],
                    [0, 0.672],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.567, 0],
                    [0, 0],
                    [0, 2.415],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.029, 0],
                    [0, 0],
                    [0, -1.449],
                    [0.966, -0.294],
                  ],
                  v: [
                    [5.986, -8.107],
                    [5.986, -11.468],
                    [7.519, -13.022],
                    [7.897, -13.022],
                    [7.897, -15.584],
                    [6.679, -15.584],
                    [2.709, -11.825],
                    [2.709, -8.023],
                    [1.785, -7.036],
                    [1.092, -7.036],
                    [1.092, -4.474],
                    [1.785, -4.474],
                    [2.709, -3.486],
                    [2.709, 0.315],
                    [6.679, 4.075],
                    [7.897, 4.075],
                    [7.897, 1.512],
                    [7.519, 1.512],
                    [5.986, -0.042],
                    [5.986, -3.402],
                    [4.579, -5.755],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: ".",
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: ".",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.134, 0],
                    [0, 1.197],
                    [1.134, 0],
                    [0, -1.218],
                  ],
                  o: [
                    [1.134, 0],
                    [0, -1.218],
                    [-1.134, 0],
                    [0, 1.197],
                  ],
                  v: [
                    [10.905, 0.168],
                    [12.943, -1.89],
                    [10.905, -3.907],
                    [8.868, -1.89],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: ".",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: ".",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.134, 0],
                    [0, 1.197],
                    [1.134, 0],
                    [0, -1.218],
                  ],
                  o: [
                    [1.134, 0],
                    [0, -1.218],
                    [-1.134, 0],
                    [0, 1.197],
                  ],
                  v: [
                    [16.369, 0.168],
                    [18.407, -1.89],
                    [16.369, -3.907],
                    [14.332, -1.89],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: ".",
          ix: 4,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: ".",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.134, 0],
                    [0, 1.197],
                    [1.134, 0],
                    [0, -1.218],
                  ],
                  o: [
                    [1.134, 0],
                    [0, -1.218],
                    [-1.134, 0],
                    [0, 1.197],
                  ],
                  v: [
                    [21.833, 0.168],
                    [23.87, -1.89],
                    [21.833, -3.907],
                    [19.796, -1.89],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "}",
          ix: 5,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "}",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0.672],
                    [0, 0],
                    [2.625, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, -0.987],
                    [0, 0],
                    [-0.966, -0.294],
                    [0, -1.449],
                    [0, 0],
                    [1.029, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 2.415],
                    [0, 0],
                    [-0.567, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-0.567, 0],
                    [0, 0],
                    [0, -2.415],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [1.029, 0],
                    [0, 0],
                    [0, 1.449],
                    [-0.966, 0.294],
                    [0, 0],
                    [0, 0.987],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [2.625, 0],
                    [0, 0],
                    [0, -0.672],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [30.973, -7.036],
                    [30.048, -8.023],
                    [30.048, -11.825],
                    [26.079, -15.584],
                    [24.861, -15.584],
                    [24.861, -13.022],
                    [25.239, -13.022],
                    [26.772, -11.468],
                    [26.772, -8.107],
                    [28.179, -5.755],
                    [26.772, -3.402],
                    [26.772, -0.042],
                    [25.239, 1.512],
                    [24.861, 1.512],
                    [24.861, 4.075],
                    [26.079, 4.075],
                    [30.048, 0.315],
                    [30.048, -3.486],
                    [30.973, -4.474],
                    [31.666, -4.474],
                    [31.666, -7.036],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 2,
    },
    {
      ty: 4,
      nm: "OBJECTS 3",
      sr: 1,
      st: 56.0000022809268,
      op: 356.000014500177,
      ip: 56.0000022809268,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [679.412, 246.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 71,
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [130, 130, 100],
              t: 88.16,
            },
            { s: [100, 100, 100], t: 97.4000039671833 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [679.412, 246.5, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [10.143, 10.143],
                    [10.143, -10.143],
                    [-10.143, -10.143],
                    [-10.143, 10.143],
                  ],
                  o: [
                    [-10.143, -10.143],
                    [-10.143, 10.143],
                    [10.143, 10.143],
                    [10.143, -10.143],
                  ],
                  v: [
                    [18.366, -18.366],
                    [-18.366, -18.366],
                    [-18.366, 18.366],
                    [18.366, 18.366],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [679.412, 246.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 3,
    },
    {
      ty: 4,
      nm: "<\\> Outlines",
      sr: 1,
      st: 49.0000019958109,
      op: 349.000014215061,
      ip: 49.0000019958109,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [16.634, -7.792, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 84,
            },
            { s: [100, 100, 100], t: 97.000003950891 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [213.689, 59.927, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [255], t: 84 },
            { s: [0], t: 97.000003950891 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "<",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "<",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [11.299, -12.518],
                    [1.302, -8.716],
                    [1.302, -5.986],
                    [11.299, -2.184],
                    [11.299, -4.81],
                    [4.306, -7.351],
                    [11.299, -9.913],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "\\",
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "\\",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [21.439, 2.1],
                    [14.508, -17.684],
                    [11.589, -17.684],
                    [18.52, 2.1],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: ">",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: ">",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [21.969, -12.518],
                    [21.969, -9.913],
                    [28.963, -7.351],
                    [21.969, -4.81],
                    [21.969, -2.184],
                    [31.967, -5.986],
                    [31.967, -8.716],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 4,
    },
    {
      ty: 4,
      nm: "OBJECTS 2",
      sr: 1,
      st: 49.0000019958109,
      op: 349.000014215061,
      ip: 49.0000019958109,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [213.634, 61.63, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 64,
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [130, 130, 100],
              t: 81.16,
            },
            { s: [100, 100, 100], t: 90.4000036820675 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [213.634, 61.63, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [10.143, 10.143],
                    [10.143, -10.143],
                    [-10.143, -10.143],
                    [-10.143, 10.143],
                  ],
                  o: [
                    [-10.143, -10.143],
                    [-10.143, 10.143],
                    [10.143, 10.143],
                    [10.143, -10.143],
                  ],
                  v: [
                    [18.366, -18.366],
                    [-18.366, -18.366],
                    [-18.366, 18.366],
                    [18.366, 18.366],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [213.634, 61.63], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 5,
    },
    {
      ty: 4,
      nm: "C:/ Outlines",
      sr: 1,
      st: 44.0000017921567,
      op: 344.000014011407,
      ip: 44.0000017921567,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [15.832, -7.792, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 79,
            },
            { s: [100, 100, 100], t: 92.0000037472368 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [97.466, 204.589, 0], ix: 2 },
        r: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0.667, y: 1 }, s: [255], t: 79 },
            { s: [0], t: 92.0000037472368 },
          ],
          ix: 10,
        },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "C",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "C",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-4.558, 0],
                    [-1.386, 1.701],
                    [0, 0],
                    [1.47, 0],
                    [0, 2.772],
                    [-2.751, 0],
                    [-0.987, -1.134],
                    [0, 0],
                    [2.541, 0],
                    [0, -4.432],
                  ],
                  o: [
                    [2.562, 0],
                    [0, 0],
                    [-0.987, 1.155],
                    [-2.751, 0],
                    [0, -2.772],
                    [1.47, 0],
                    [0, 0],
                    [-1.386, -1.68],
                    [-4.579, 0],
                    [0, 4.432],
                  ],
                  v: [
                    [8.779, 0.252],
                    [14.849, -2.373],
                    [12.665, -4.39],
                    [8.968, -2.646],
                    [4.264, -7.351],
                    [8.968, -12.056],
                    [12.665, -10.333],
                    [14.849, -12.35],
                    [8.8, -14.954],
                    [0.819, -7.351],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: ":",
          ix: 2,
          cix: 2,
          np: 5,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: ":",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.134, 0],
                    [0, 1.197],
                    [1.134, 0],
                    [0, -1.218],
                  ],
                  o: [
                    [1.134, 0],
                    [0, -1.218],
                    [-1.134, 0],
                    [0, 1.197],
                  ],
                  v: [
                    [17.871, -7.372],
                    [19.908, -9.43],
                    [17.871, -11.447],
                    [15.834, -9.43],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: ":",
              ix: 2,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-1.134, 0],
                    [0, 1.197],
                    [1.134, 0],
                    [0, -1.218],
                  ],
                  o: [
                    [1.134, 0],
                    [0, -1.218],
                    [-1.134, 0],
                    [0, 1.197],
                  ],
                  v: [
                    [17.871, 0.168],
                    [19.908, -1.89],
                    [17.871, -3.907],
                    [15.834, -1.89],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "mm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Merge",
              nm: "Merge Paths 1",
              mm: 1,
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "/",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "/",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [20.996, 2.1],
                    [23.915, 2.1],
                    [30.846, -17.684],
                    [27.927, -17.684],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 6,
    },
    {
      ty: 4,
      nm: "OBJECTS",
      sr: 1,
      st: 44.0000017921567,
      op: 344.000014011407,
      ip: 44.0000017921567,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [97.328, 205.634, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 59,
            },
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [130, 130, 100],
              t: 76.16,
            },
            { s: [100, 100, 100], t: 85.4000034784133 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [97.328, 205.634, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-10.143, 10.143],
                    [-10.143, -10.143],
                    [10.143, -10.143],
                    [10.143, 10.143],
                  ],
                  o: [
                    [10.143, -10.143],
                    [10.143, 10.143],
                    [-10.143, 10.143],
                    [-10.143, -10.143],
                  ],
                  v: [
                    [-18.366, -18.366],
                    [18.366, -18.366],
                    [18.366, 18.366],
                    [-18.366, 18.366],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [97.328, 205.634], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 7,
    },
    {
      ty: 4,
      nm: "Layer 9",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [613.856, 362.784, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 69 },
            { s: [100, 100, 100], t: 89.0000036250443 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [0, 21.019, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -1.312],
                            [1.312, 0],
                            [0, 1.312],
                            [-1.312, 0],
                          ],
                          o: [
                            [0, 1.312],
                            [-1.312, 0],
                            [0, -1.312],
                            [1.312, 0],
                          ],
                          v: [
                            [2.375, 0],
                            [0, 2.375],
                            [-2.375, 0],
                            [0, -2.375],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [615.511, 371.557], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [64.501, 0.384],
                            [-64.501, 0.384],
                            [-64.501, -0.384],
                            [64.501, -0.384],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [613.856, 371.62], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [613.856, 371.62], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [613.856, 371.62], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Group 1",
                      ix: 1,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "sh",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Group",
                          nm: "Path 1",
                          ix: 1,
                          d: 1,
                          ks: {
                            a: 0,
                            k: {
                              c: true,
                              i: [
                                [0.43, 0],
                                [0, 0],
                                [0, 0.43],
                                [0, 0],
                                [-0.43, 0],
                                [0, 0],
                                [0, -0.43],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.43, 0],
                                [0, 0],
                                [0, -0.43],
                                [0, 0],
                                [0.43, 0],
                                [0, 0],
                                [0, 0.43],
                              ],
                              v: [
                                [1.178, 1.957],
                                [-1.178, 1.957],
                                [-1.957, 1.178],
                                [-1.957, -1.178],
                                [-1.178, -1.957],
                                [1.178, -1.957],
                                [1.957, -1.178],
                                [1.957, 1.178],
                              ],
                            },
                            ix: 2,
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill 1",
                          c: { a: 0, k: [1, 1, 1], ix: 4 },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [694.53, 371.476], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Group 2",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "sh",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Group",
                          nm: "Path 1",
                          ix: 1,
                          d: 1,
                          ks: {
                            a: 0,
                            k: {
                              c: true,
                              i: [
                                [0.43, 0],
                                [0, 0],
                                [0, 0.43],
                                [0, 0],
                                [-0.43, 0],
                                [0, 0],
                                [0, -0.43],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.43, 0],
                                [0, 0],
                                [0, -0.43],
                                [0, 0],
                                [0.43, 0],
                                [0, 0],
                                [0, 0.43],
                              ],
                              v: [
                                [1.178, 1.957],
                                [-1.178, 1.957],
                                [-1.957, 1.178],
                                [-1.957, -1.178],
                                [-1.178, -1.957],
                                [1.178, -1.957],
                                [1.957, -1.178],
                                [1.957, 1.178],
                              ],
                            },
                            ix: 2,
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill 1",
                          c: { a: 0, k: [1, 1, 1], ix: 4 },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [685.936, 371.476], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Group 3",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Group 1",
                          ix: 1,
                          cix: 2,
                          np: 2,
                          it: [
                            {
                              ty: "sh",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Shape - Group",
                              nm: "Path 1",
                              ix: 1,
                              d: 1,
                              ks: {
                                a: 0,
                                k: {
                                  c: true,
                                  i: [
                                    [0, -0.397],
                                    [0.397, 0],
                                    [0, 0.397],
                                    [-0.397, 0],
                                  ],
                                  o: [
                                    [0, 0.397],
                                    [-0.397, 0],
                                    [0, -0.397],
                                    [0.397, 0],
                                  ],
                                  v: [
                                    [0.719, 0],
                                    [0, 0.719],
                                    [-0.719, 0],
                                    [0, -0.719],
                                  ],
                                },
                                ix: 2,
                              },
                            },
                            {
                              ty: "fl",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Graphic - Fill",
                              nm: "Fill 1",
                              c: { a: 0, k: [1, 1, 1], ix: 4 },
                              r: 1,
                              o: { a: 0, k: 100, ix: 5 },
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [690.233, 372.714], ix: 2 },
                              r: { a: 0, k: 0, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Group 2",
                          ix: 2,
                          cix: 2,
                          np: 2,
                          it: [
                            {
                              ty: "sh",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Shape - Group",
                              nm: "Path 1",
                              ix: 1,
                              d: 1,
                              ks: {
                                a: 0,
                                k: {
                                  c: true,
                                  i: [
                                    [0, -0.397],
                                    [0.397, 0],
                                    [0, 0.397],
                                    [-0.397, 0],
                                  ],
                                  o: [
                                    [0, 0.397],
                                    [-0.397, 0],
                                    [0, -0.397],
                                    [0.397, 0],
                                  ],
                                  v: [
                                    [0.719, 0],
                                    [0, 0.719],
                                    [-0.719, 0],
                                    [0, -0.719],
                                  ],
                                },
                                ix: 2,
                              },
                            },
                            {
                              ty: "fl",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Graphic - Fill",
                              nm: "Fill 1",
                              c: { a: 0, k: [1, 1, 1], ix: 4 },
                              r: 1,
                              o: { a: 0, k: 100, ix: 5 },
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [690.233, 370.237], ix: 2 },
                              r: { a: 0, k: 0, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [690.233, 370.237], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [690.233, 370.237], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [690.233, 370.237], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [690.233, 370.237], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 3,
                  it: [
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Group 1",
                      ix: 1,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "sh",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Group",
                          nm: "Path 1",
                          ix: 1,
                          d: 1,
                          ks: {
                            a: 0,
                            k: {
                              c: true,
                              i: [
                                [0.423, 0],
                                [0, 0],
                                [0, 0.423],
                                [0, 0],
                                [-0.423, 0],
                                [0, 0],
                                [0, -0.423],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.423, 0],
                                [0, 0],
                                [0, -0.423],
                                [0, 0],
                                [0.423, 0],
                                [0, 0],
                                [0, 0.423],
                              ],
                              v: [
                                [1.191, 1.957],
                                [-1.191, 1.957],
                                [-1.957, 1.191],
                                [-1.957, -1.191],
                                [-1.191, -1.957],
                                [1.191, -1.957],
                                [1.957, -1.191],
                                [1.957, 1.191],
                              ],
                            },
                            ix: 2,
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill 1",
                          c: { a: 0, k: [1, 1, 1], ix: 4 },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [541.775, 371.476], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Group 2",
                      ix: 2,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "sh",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Shape - Group",
                          nm: "Path 1",
                          ix: 1,
                          d: 1,
                          ks: {
                            a: 0,
                            k: {
                              c: true,
                              i: [
                                [0.432, 0],
                                [0, 0],
                                [0, 0.432],
                                [0, 0],
                                [-0.432, 0],
                                [0, 0],
                                [0, -0.432],
                                [0, 0],
                              ],
                              o: [
                                [0, 0],
                                [-0.432, 0],
                                [0, 0],
                                [0, -0.432],
                                [0, 0],
                                [0.432, 0],
                                [0, 0],
                                [0, 0.432],
                              ],
                              v: [
                                [1.175, 1.957],
                                [-1.175, 1.957],
                                [-1.957, 1.175],
                                [-1.957, -1.175],
                                [-1.175, -1.957],
                                [1.175, -1.957],
                                [1.957, -1.175],
                                [1.957, 1.175],
                              ],
                            },
                            ix: 2,
                          },
                        },
                        {
                          ty: "fl",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Graphic - Fill",
                          nm: "Fill 1",
                          c: { a: 0, k: [1, 1, 1], ix: 4 },
                          r: 1,
                          o: { a: 0, k: 100, ix: 5 },
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [0, 0], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [533.181, 371.476], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "gr",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Group",
                      nm: "Group 3",
                      ix: 3,
                      cix: 2,
                      np: 2,
                      it: [
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Group 1",
                          ix: 1,
                          cix: 2,
                          np: 2,
                          it: [
                            {
                              ty: "sh",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Shape - Group",
                              nm: "Path 1",
                              ix: 1,
                              d: 1,
                              ks: {
                                a: 0,
                                k: {
                                  c: true,
                                  i: [
                                    [0, -0.397],
                                    [0.397, 0],
                                    [0, 0.397],
                                    [-0.397, 0],
                                  ],
                                  o: [
                                    [0, 0.397],
                                    [-0.397, 0],
                                    [0, -0.397],
                                    [0.397, 0],
                                  ],
                                  v: [
                                    [0.719, 0],
                                    [0, 0.719],
                                    [-0.719, 0],
                                    [0, -0.719],
                                  ],
                                },
                                ix: 2,
                              },
                            },
                            {
                              ty: "fl",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Graphic - Fill",
                              nm: "Fill 1",
                              c: { a: 0, k: [1, 1, 1], ix: 4 },
                              r: 1,
                              o: { a: 0, k: 100, ix: 5 },
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [537.478, 372.714], ix: 2 },
                              r: { a: 0, k: 0, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "gr",
                          bm: 0,
                          hd: false,
                          mn: "ADBE Vector Group",
                          nm: "Group 2",
                          ix: 2,
                          cix: 2,
                          np: 2,
                          it: [
                            {
                              ty: "sh",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Shape - Group",
                              nm: "Path 1",
                              ix: 1,
                              d: 1,
                              ks: {
                                a: 0,
                                k: {
                                  c: true,
                                  i: [
                                    [0, -0.397],
                                    [0.397, 0],
                                    [0, 0.397],
                                    [-0.397, 0],
                                  ],
                                  o: [
                                    [0, 0.397],
                                    [-0.397, 0],
                                    [0, -0.397],
                                    [0.397, 0],
                                  ],
                                  v: [
                                    [0.719, 0],
                                    [0, 0.719],
                                    [-0.719, 0],
                                    [0, -0.719],
                                  ],
                                },
                                ix: 2,
                              },
                            },
                            {
                              ty: "fl",
                              bm: 0,
                              hd: false,
                              mn: "ADBE Vector Graphic - Fill",
                              nm: "Fill 1",
                              c: { a: 0, k: [1, 1, 1], ix: 4 },
                              r: 1,
                              o: { a: 0, k: 100, ix: 5 },
                            },
                            {
                              ty: "tr",
                              a: { a: 0, k: [0, 0], ix: 1 },
                              s: { a: 0, k: [100, 100], ix: 3 },
                              sk: { a: 0, k: 0, ix: 4 },
                              p: { a: 0, k: [537.478, 370.237], ix: 2 },
                              r: { a: 0, k: 0, ix: 6 },
                              sa: { a: 0, k: 0, ix: 5 },
                              o: { a: 0, k: 100, ix: 7 },
                            },
                          ],
                        },
                        {
                          ty: "tr",
                          a: { a: 0, k: [537.478, 370.237], ix: 1 },
                          s: { a: 0, k: [100, 100], ix: 3 },
                          sk: { a: 0, k: 0, ix: 4 },
                          p: { a: 0, k: [537.478, 370.237], ix: 2 },
                          r: { a: 0, k: 0, ix: 6 },
                          sa: { a: 0, k: 0, ix: 5 },
                          o: { a: 0, k: 100, ix: 7 },
                        },
                      ],
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [537.478, 370.237], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [537.478, 370.237], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [537.478, 370.237], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [537.478, 370.237], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [537.478, 370.237], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [537.478, 370.237], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [-0.269, 0],
                            [0, 0],
                            [0, 0.269],
                            [0, 0],
                            [0.269, 0],
                            [0, 0],
                            [0, -0.269],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0.269, 0],
                            [0, 0],
                            [0, -0.269],
                            [0, 0],
                            [-0.269, 0],
                            [0, 0],
                            [0, 0.269],
                          ],
                          v: [
                            [-0.234, 4.071],
                            [0.234, 4.071],
                            [0.721, 3.584],
                            [0.721, -3.583],
                            [0.234, -4.071],
                            [-0.234, -4.071],
                            [-0.721, -3.583],
                            [-0.721, 3.584],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [581.628, 356.616], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, 0.483],
                            [0, 0],
                            [0.431, -0.213],
                            [0, 0],
                            [-0.477, -0.241],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, -0.48],
                            [0, 0],
                            [-0.479, 0.236],
                            [0, 0],
                            [0.431, 0.218],
                          ],
                          v: [
                            [4.07, 3.422],
                            [4.07, -3.422],
                            [3.135, -4.003],
                            [-3.709, -0.622],
                            [-3.714, 0.537],
                            [3.129, 4],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [587.61, 356.616], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [587.61, 356.616], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [587.61, 356.616], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0.269, 0],
                            [0, 0],
                            [0, 0.269],
                            [0, 0],
                            [-0.269, 0],
                            [0, 0],
                            [0, -0.269],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-0.269, 0],
                            [0, 0],
                            [0, -0.269],
                            [0, 0],
                            [0.269, 0],
                            [0, 0],
                            [0, 0.269],
                          ],
                          v: [
                            [0.234, 4.071],
                            [-0.234, 4.071],
                            [-0.721, 3.584],
                            [-0.721, -3.583],
                            [-0.234, -4.071],
                            [0.234, -4.071],
                            [0.721, -3.583],
                            [0.721, 3.584],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [646.084, 356.616], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, 0.483],
                            [0, 0],
                            [-0.431, -0.213],
                            [0, 0],
                            [0.476, -0.241],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, -0.48],
                            [0, 0],
                            [0.479, 0.236],
                            [0, 0],
                            [-0.431, 0.218],
                          ],
                          v: [
                            [-4.07, 3.422],
                            [-4.07, -3.422],
                            [-3.135, -4.003],
                            [3.709, -0.622],
                            [3.715, 0.537],
                            [-3.129, 4],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [640.102, 356.616], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [640.102, 356.616], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [640.102, 356.616], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0.675],
                        [0, 0],
                        [-0.602, -0.297],
                        [0, 0],
                        [0.666, -0.337],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, -0.671],
                        [0, 0],
                        [0.669, 0.331],
                        [0, 0],
                        [-0.603, 0.305],
                      ],
                      v: [
                        [-5.69, 4.784],
                        [-5.69, -4.784],
                        [-4.383, -5.596],
                        [5.185, -0.87],
                        [5.193, 0.75],
                        [-4.375, 5.592],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [613.856, 356.616], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [613.856, 356.616], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [613.856, 356.616], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [4.108, 0],
                    [0, 0],
                    [0, 4.108],
                    [0, 0],
                    [-4.108, 0],
                    [0, 0],
                    [0, -4.108],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.108, 0],
                    [0, 0],
                    [0, -4.108],
                    [0, 0],
                    [4.108, 0],
                    [0, 0],
                    [0, 4.108],
                  ],
                  v: [
                    [82.07, 19.071],
                    [-82.07, 19.071],
                    [-89.509, 11.632],
                    [-89.509, -11.632],
                    [-82.07, -19.071],
                    [82.07, -19.071],
                    [89.509, -11.632],
                    [89.509, 11.632],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [613.856, 362.784], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 8,
      parent: 11,
    },
    {
      ty: 4,
      nm: "Layer 10",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [605.316, 319.873, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [605.316, 319.873, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [1.443, 0],
                        [0, 0],
                        [0, 1.443],
                        [0, 0],
                        [-1.443, 0],
                        [0, 0],
                        [0, -1.443],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.443, 0],
                        [0, 0],
                        [0, -1.443],
                        [0, 0],
                        [1.443, 0],
                        [0, 0],
                        [0, 1.443],
                      ],
                      v: [
                        [30.616, 2.613],
                        [-30.616, 2.613],
                        [-33.23, 0],
                        [-33.23, 0],
                        [-30.616, -2.613],
                        [30.616, -2.613],
                        [33.23, 0],
                        [33.23, 0],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-33.25, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.31, y: 0 },
                    i: { x: 0, y: 1 },
                    s: [0, 100],
                    t: 74,
                  },
                  { s: [100, 100], t: 95.0000038694293 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [568.68, 320.825], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [2.177, 0],
                        [0, 0],
                        [0, 2.177],
                        [0, 0],
                        [-2.177, 0],
                        [0, 0],
                        [0, -2.177],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-2.177, 0],
                        [0, 0],
                        [0, -2.177],
                        [0, 0],
                        [2.177, 0],
                        [0, 0],
                        [0, 2.177],
                      ],
                      v: [
                        [54.676, 3.942],
                        [-54.676, 3.942],
                        [-58.618, 0],
                        [-58.618, 0],
                        [-54.676, -3.942],
                        [54.676, -3.942],
                        [58.618, 0],
                        [58.618, 0],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-58.5, -1], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.31, y: 0 },
                    i: { x: 0, y: 1 },
                    s: [0, 100],
                    t: 74,
                  },
                  { s: [100, 100], t: 95.0000038694293 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [568.819, 306.408], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.914, 0],
                    [0, 0],
                    [0, 1.914],
                    [0, 0],
                    [-1.914, 0],
                    [0, 0],
                    [0, -1.914],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.914, 0],
                    [0, 0],
                    [0, -1.914],
                    [0, 0],
                    [1.914, 0],
                    [0, 0],
                    [0, 1.914],
                  ],
                  v: [
                    [12.941, 16.407],
                    [-12.941, 16.407],
                    [-16.407, 12.941],
                    [-16.407, -12.941],
                    [-12.941, -16.407],
                    [12.941, -16.407],
                    [16.407, -12.941],
                    [16.407, 12.941],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 69 },
                  { s: [100, 100], t: 90.0000036657751 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [541.104, 319.873], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 9,
      parent: 10,
    },
    {
      ty: 4,
      nm: "Layer 11",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [703.96, 299.817, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [90.104, -41.948, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [3.075, -1.838],
                    [1.838, -3.075],
                    [0, -1.237],
                    [-1.838, -3.075],
                    [-3.075, -1.838],
                    [-1.237, 0],
                    [-3.075, 1.838],
                    [-1.838, 3.075],
                    [0, 1.237],
                    [1.838, 3.075],
                    [3.075, 1.838],
                    [1.237, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [703.96, 299.817], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-0.02, -2.768],
                    [2.769, -0.02],
                    [0.02, 2.769],
                    [-2.769, 0.02],
                  ],
                  o: [
                    [0.02, 2.769],
                    [-2.768, 0.02],
                    [-0.02, -2.769],
                    [2.769, -0.02],
                  ],
                  v: [
                    [5.013, -0.037],
                    [0.037, 5.013],
                    [-5.013, 0.037],
                    [-0.037, -5.013],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [703.96, 299.817], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 10,
      parent: 11,
    },
    {
      ty: 4,
      nm: "Layer 12",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 55 },
            { s: [100, 100, 100], t: 76.0000030955435 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [613.856, 341.765, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [4.032, 0],
                    [0, 0],
                    [0, 4.032],
                    [0, 0],
                    [-4.032, 0],
                    [0, 0],
                    [0, -4.033],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.032, 0],
                    [0, 0],
                    [0, -4.033],
                    [0, 0],
                    [4.032, 0],
                    [0, 0],
                    [0, 4.032],
                  ],
                  v: [
                    [90.833, 50.332],
                    [-90.833, 50.332],
                    [-98.135, 43.031],
                    [-98.135, -43.03],
                    [-90.833, -50.332],
                    [90.833, -50.332],
                    [98.135, -43.03],
                    [98.135, 43.031],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 11,
    },
    {
      ty: 4,
      nm: "Layer 29",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [193.469, 394.575, 0], ix: 1 },
        s: { a: 0, k: [-100, -100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [560.469, 255.075, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -2.13],
                    [2.13, 0],
                    [0, 2.13],
                    [-2.13, 0],
                  ],
                  o: [
                    [0, 2.13],
                    [-2.13, 0],
                    [0, -2.13],
                    [2.13, 0],
                  ],
                  v: [
                    [3.856, 0],
                    [0, 3.856],
                    [-3.856, 0],
                    [0, -3.856],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 51 },
                  { s: [100, 100], t: 54.0000021994651 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [124.826, 370.052], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -2.13],
                    [2.13, 0],
                    [0, 2.13],
                    [-2.13, 0],
                  ],
                  o: [
                    [0, 2.13],
                    [-2.13, 0],
                    [0, -2.13],
                    [2.13, 0],
                  ],
                  v: [
                    [3.856, 0],
                    [0, 3.856],
                    [-3.856, 0],
                    [0, -3.856],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 40 },
                  { s: [100, 100], t: 43.0000017514259 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [262.113, 390.719], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [-2.505, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 6],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-68.643, -10.333],
                    [-68.643, 3.281],
                    [-62.002, 10.333],
                    [68.643, 10.333],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 2,
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 43 },
                  { s: [0], t: 53.0000021587343 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [193.469, 380.385], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 12,
    },
    {
      ty: 4,
      nm: "Layer 14",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [193.469, 394.575, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [193.469, 394.575, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -2.13],
                    [2.13, 0],
                    [0, 2.13],
                    [-2.13, 0],
                  ],
                  o: [
                    [0, 2.13],
                    [-2.13, 0],
                    [0, -2.13],
                    [2.13, 0],
                  ],
                  v: [
                    [3.856, 0],
                    [0, 3.856],
                    [-3.856, 0],
                    [0, -3.856],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 58 },
                  { s: [100, 100], t: 61.0000024845809 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [124.826, 370.052], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -2.13],
                    [2.13, 0],
                    [0, 2.13],
                    [-2.13, 0],
                  ],
                  o: [
                    [0, 2.13],
                    [-2.13, 0],
                    [0, -2.13],
                    [2.13, 0],
                  ],
                  v: [
                    [3.856, 0],
                    [0, 3.856],
                    [-3.856, 0],
                    [0, -3.856],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 47 },
                  { s: [100, 100], t: 50.0000020365418 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [262.113, 390.719], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [-2.505, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 6],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-68.643, -10.333],
                    [-68.643, 3.281],
                    [-62.002, 10.333],
                    [68.643, 10.333],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 2,
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 50 },
                  { s: [0], t: 60.0000024438501 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [193.469, 380.385], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 13,
    },
    {
      ty: 4,
      nm: "Shape Layer 12",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [133.875, 318.375, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [133.875, 318.375, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 112",
          ix: 1,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 81,
                  },
                  { s: [100, 55], t: 104.000004236007 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [185.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 111",
          ix: 2,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [185.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 110",
          ix: 3,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 81,
                  },
                  { s: [100, 77], t: 104.000004236007 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [164.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 9",
          ix: 4,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [164.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 8",
          ix: 5,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 81,
                  },
                  { s: [100, 95], t: 104.000004236007 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [144.125, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 7",
          ix: 6,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [144.125, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 6",
          ix: 7,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 81,
                  },
                  { s: [100, 75], t: 104.000004236007 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [122.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 5",
          ix: 8,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [122.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 4",
          ix: 9,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 81,
                  },
                  { s: [100, 40], t: 104.000004236007 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [102.375, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 3",
          ix: 10,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [102.375, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 2",
          ix: 11,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [100, 0],
                    t: 81,
                  },
                  { s: [100, 55], t: 104.000004236007 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [81.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Shape Layer 1",
          ix: 12,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Rectangle 1",
              ix: 1,
              cix: 2,
              np: 3,
              it: [
                {
                  ty: "rc",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Rect",
                  nm: "Rectangle Path 1",
                  d: 1,
                  p: { a: 0, k: [0, 0], ix: 3 },
                  r: { a: 0, k: 0, ix: 4 },
                  s: { a: 0, k: [8.25, 62.75], ix: 2 },
                },
                {
                  ty: "st",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Stroke",
                  nm: "Stroke 1",
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 0, ix: 5 },
                  c: { a: 0, k: [1, 1, 1], ix: 3 },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [-293.125, 68.375], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [-293.125, 99.75], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [81.875, 349.75], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 14,
      parent: 15,
    },
    {
      ty: 4,
      nm: "Layer 4",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [126.081, 361.135, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.31, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 61 },
            { s: [100, 100, 100], t: 81.0000032991976 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [126.081, 361.135, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.509, 0],
                    [0, 0],
                    [0, 1.509],
                    [0, 0],
                    [-1.509, 0],
                    [0, 0],
                    [0, -1.509],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.509, 0],
                    [0, 0],
                    [0, -1.509],
                    [0, 0],
                    [1.509, 0],
                    [0, 0],
                    [0, 1.509],
                  ],
                  v: [
                    [53.997, 2.732],
                    [-53.997, 2.732],
                    [-56.729, 0],
                    [-56.729, 0],
                    [-53.997, -2.732],
                    [53.997, -2.732],
                    [56.729, 0],
                    [56.729, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.8706, 0.9137, 0.9961], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [117.389, 265.706], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.075, -1.838],
                            [1.838, -3.075],
                            [0, -1.237],
                            [-1.838, -3.075],
                            [-3.075, -1.838],
                            [-1.237, 0],
                            [-3.075, 1.838],
                            [-1.838, 3.075],
                            [0, 1.237],
                            [1.838, 3.075],
                            [3.075, 1.838],
                            [1.237, 0],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [206.501, 261.738], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [-0.02, -2.769],
                            [2.768, -0.02],
                            [0.02, 2.769],
                            [-2.768, 0.02],
                          ],
                          o: [
                            [0.02, 2.769],
                            [-2.769, 0.02],
                            [-0.02, -2.769],
                            [2.769, -0.02],
                          ],
                          v: [
                            [5.013, -0.037],
                            [0.037, 5.013],
                            [-5.013, 0.037],
                            [-0.037, -5.013],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [206.501, 261.738], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [206.501, 261.738], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [206.501, 261.738], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [2.62, 0],
                        [0, 0],
                        [0, 2.62],
                        [0, 0],
                        [-2.62, 0],
                        [0, 0],
                        [0, -2.62],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-2.62, 0],
                        [0, 0],
                        [0, -2.62],
                        [0, 0],
                        [2.62, 0],
                        [0, 0],
                        [0, 2.62],
                      ],
                      v: [
                        [68.176, 40.393],
                        [-68.177, 40.393],
                        [-72.921, 35.649],
                        [-72.921, -35.649],
                        [-68.177, -40.393],
                        [68.176, -40.393],
                        [72.921, -35.649],
                        [72.921, 35.649],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [133.581, 316.342], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [133.581, 316.342], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [133.581, 316.342], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [4.379, 0],
                    [0, 0],
                    [0, 4.379],
                    [0, 0],
                    [-4.379, 0],
                    [0, 0],
                    [0, -4.379],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.379, 0],
                    [0, 0],
                    [0, -4.379],
                    [0, 0],
                    [4.379, 0],
                    [0, 0],
                    [0, 4.379],
                  ],
                  v: [
                    [72.829, 54.749],
                    [-72.829, 54.749],
                    [-80.757, 46.82],
                    [-80.757, -46.82],
                    [-72.829, -54.749],
                    [72.829, -54.749],
                    [80.757, -46.82],
                    [80.757, 46.82],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [133.581, 308.386], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 15,
    },
    {
      ty: 4,
      nm: "Layer 15",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [514.641, 154.89, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [514.641, 154.89, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.13, 0],
                    [0, 2.13],
                    [-2.13, 0],
                    [0, -2.13],
                  ],
                  o: [
                    [-2.13, 0],
                    [0, -2.13],
                    [2.13, 0],
                    [0, 2.13],
                  ],
                  v: [
                    [0, 3.856],
                    [-3.856, 0],
                    [0, -3.856],
                    [3.856, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 41 },
                  { s: [100, 100], t: 45.0000018328876 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [524.975, 103.272], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.13, 0],
                    [0, 2.13],
                    [-2.13, 0],
                    [0, -2.13],
                  ],
                  o: [
                    [-2.13, 0],
                    [0, -2.13],
                    [2.13, 0],
                    [0, 2.13],
                  ],
                  v: [
                    [0, 3.856],
                    [-3.856, 0],
                    [0, -3.856],
                    [3.856, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 28 },
                  { s: [100, 100], t: 33.0000013441176 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [504.308, 206.508], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -2.505],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-6.859, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [10.333, -34.586],
                    [-3.475, -34.586],
                    [-10.333, -27.945],
                    [-10.167, 69.086],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 3 },
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 3,
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 33 },
                  { s: [0], t: 43.0000017514259 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [514.641, 137.141], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 16,
    },
    {
      ty: 4,
      nm: "Layer 5",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [535.92, 102.791, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 43 },
            { s: [100, 100, 100], t: 63.0000025660426 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [535.92, 102.791, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.382, 0.004],
                    [0, 0],
                    [0, 1.376],
                    [0, 0],
                    [-1.382, -0.004],
                    [0, 0],
                    [0, -1.376],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.376, -0.004],
                    [0, 0],
                    [0, -1.382],
                    [0, 0],
                    [1.376, 0.004],
                    [0, 0],
                    [0, 1.382],
                  ],
                  v: [
                    [19.875, 2.561],
                    [-19.891, 2.434],
                    [-22.38, -0.064],
                    [-22.38, -0.064],
                    [-19.875, -2.561],
                    [19.891, -2.434],
                    [22.38, 0.064],
                    [22.38, 0.064],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [-22.785, -0.351], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 100],
                    t: 72,
                  },
                  { s: [100, 100], t: 87.0000035435826 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [544.511, 182.945], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.38, 0.002],
                    [0, 0],
                    [0, 1.378],
                    [0, 0],
                    [-1.38, -0.002],
                    [0, 0],
                    [0, -1.378],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.378, -0.002],
                    [0, 0],
                    [0, -1.38],
                    [0, 0],
                    [1.378, 0.002],
                    [0, 0],
                    [0, 1.38],
                  ],
                  v: [
                    [60.75, 2.662],
                    [-60.756, 2.519],
                    [-63.25, 0.022],
                    [-63.25, -0.165],
                    [-60.75, -2.662],
                    [60.756, -2.519],
                    [63.25, -0.022],
                    [63.25, 0.165],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [-63.449, -0.351], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 100],
                    t: 64,
                  },
                  { s: [100, 100], t: 79.000003217736 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [544.717, 170.613], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.432, 0.002],
                    [0, 0],
                    [0, 1.429],
                    [0, 0],
                    [-1.432, -0.002],
                    [0, 0],
                    [0, -1.429],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.429, -0.002],
                    [0, 0],
                    [0, -1.432],
                    [0, 0],
                    [1.429, 0.002],
                    [0, 0],
                    [0, 1.432],
                  ],
                  v: [
                    [41.041, 2.66],
                    [-41.049, 2.521],
                    [-43.635, -0.07],
                    [-43.635, -0.07],
                    [-41.041, -2.66],
                    [41.049, -2.521],
                    [43.635, 0.07],
                    [43.635, 0.07],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [-43.468, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 100],
                    t: 56,
                  },
                  { s: [100, 100], t: 71.0000028918893 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [545.084, 64.297], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          ix: 4,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.075, -1.838],
                        [1.838, -3.075],
                        [0, -1.237],
                        [-1.838, -3.075],
                        [-3.075, -1.838],
                        [-1.237, 0],
                        [-3.075, 1.838],
                        [-1.838, 3.075],
                        [0, 1.237],
                        [1.838, 3.075],
                        [3.075, 1.838],
                        [1.237, 0],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [668.84, 56.949], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.02, -2.769],
                        [2.769, -0.02],
                        [0.02, 2.769],
                        [-2.769, 0.02],
                      ],
                      o: [
                        [0.02, 2.769],
                        [-2.768, 0.02],
                        [-0.02, -2.769],
                        [2.768, -0.02],
                      ],
                      v: [
                        [5.013, -0.037],
                        [0.037, 5.013],
                        [-5.013, 0.037],
                        [-0.037, -5.013],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [668.84, 56.949], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [668.84, 56.949], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [668.84, 56.949], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          ix: 5,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -3.235],
                            [3.235, 0],
                            [0, 3.235],
                            [-3.235, 0],
                          ],
                          o: [
                            [0, 3.235],
                            [-3.235, 0],
                            [0, -3.235],
                            [3.235, 0],
                          ],
                          v: [
                            [5.857, 0],
                            [0, 5.857],
                            [-5.857, 0],
                            [0, -5.857],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [645.291, 126.426], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -5.328],
                            [0, 0],
                            [0, 5.328],
                            [-5.328, 0],
                          ],
                          o: [
                            [0, 5.328],
                            [0, 0],
                            [0, -5.328],
                            [5.328, 0],
                          ],
                          v: [
                            [9.647, -4.824],
                            [0, 14.471],
                            [-9.647, -4.824],
                            [0, -14.471],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [645.291, 131.286], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [645.291, 145.833], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0.667, y: 1 },
                        s: [0, 0],
                        t: 83,
                      },
                      { s: [100, 100], t: 98.0000039916218 },
                    ],
                    ix: 3,
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [645.291, 145.833], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -3.235],
                            [3.235, 0],
                            [0, 3.235],
                            [-3.235, 0],
                          ],
                          o: [
                            [0, 3.235],
                            [-3.235, 0],
                            [0, -3.235],
                            [3.235, 0],
                          ],
                          v: [
                            [5.857, 0],
                            [0, 5.857],
                            [-5.857, 0],
                            [0, -5.857],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [571.041, 126.426], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -5.328],
                            [0, 0],
                            [0, 5.328],
                            [-5.328, 0],
                          ],
                          o: [
                            [0, 5.328],
                            [0, 0],
                            [0, -5.328],
                            [5.328, 0],
                          ],
                          v: [
                            [9.647, -4.824],
                            [0, 14.471],
                            [-9.647, -4.824],
                            [0, -14.471],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [571.041, 131.286], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [571.041, 145.746], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0.667, y: 1 },
                        s: [0, 0],
                        t: 64,
                      },
                      { s: [100, 100], t: 79.000003217736 },
                    ],
                    ix: 3,
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [571.041, 145.746], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [571.041, 131.286], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [571.041, 131.286], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          ix: 6,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -6.526],
                        [6.526, 0],
                        [0, 6.526],
                        [-6.526, 0],
                      ],
                      o: [
                        [0, 6.526],
                        [-6.526, 0],
                        [0, -6.526],
                        [6.526, 0],
                      ],
                      v: [
                        [11.817, 0],
                        [0, 11.817],
                        [-11.817, 0],
                        [0, -11.817],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [608.166, 106.758], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -10.749],
                        [0, 0],
                        [0, 10.749],
                        [-10.749, 0],
                      ],
                      o: [
                        [0, 10.749],
                        [0, 0],
                        [0, -10.749],
                        [10.749, 0],
                      ],
                      v: [
                        [19.463, -9.731],
                        [0, 29.194],
                        [-19.463, -9.731],
                        [0, -29.194],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [608.166, 116.563], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [608.166, 145.658], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 75,
                  },
                  { s: [100, 100], t: 90.0000036657751 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [608.166, 145.658], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          ix: 7,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-37.125, 3.667],
                    [-18.489, -3.667],
                    [0, 3.667],
                    [19.463, -3.667],
                    [37.125, 3.667],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 2,
              e: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0],
                    t: 62,
                  },
                  { s: [100], t: 77.0000031362743 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 0, ix: 3 },
              s: { a: 0, k: 0, ix: 1 },
              m: 1,
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 2,
              lj: 2,
              ml: 1,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0.75, ix: 5 },
              d: [{ nm: "dash", n: "d", v: { a: 0, k: 3, ix: 1 } }],
              c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [608.166, 146.718], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          ix: 8,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.756, 0],
                    [0, 0],
                    [0, 3.756],
                    [0, 0],
                    [-3.756, 0],
                    [0, 0],
                    [0, -3.756],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.756, 0],
                    [0, 0],
                    [0, -3.756],
                    [0, 0],
                    [3.756, 0],
                    [0, 0],
                    [0, 3.756],
                  ],
                  v: [
                    [56.45, 42.75],
                    [-56.45, 42.75],
                    [-63.25, 35.95],
                    [-63.25, -35.95],
                    [-56.45, -42.75],
                    [56.45, -42.75],
                    [63.25, -35.95],
                    [63.25, 35.95],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 50,
                  },
                  { s: [100, 100], t: 65.0000026475043 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [608.166, 117.96], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          ix: 9,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [7.842, 0],
                    [0, 0],
                    [0, 7.842],
                    [0, 0],
                    [-7.842, 0],
                    [0, 0],
                    [0, -7.842],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-7.842, 0],
                    [0, 0],
                    [0, -7.842],
                    [0, 0],
                    [7.842, 0],
                    [0, 0],
                    [0, 7.842],
                  ],
                  v: [
                    [57.046, 79.762],
                    [-57.047, 79.762],
                    [-71.246, 65.562],
                    [-71.246, -65.562],
                    [-57.047, -79.762],
                    [57.046, -79.762],
                    [71.246, -65.562],
                    [71.246, 65.562],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [608.166, 126.291], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 17,
    },
    {
      ty: 4,
      nm: "Layer 28",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [192.993, 148.389, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [192.993, 148.389, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 2.13],
                    [2.13, 0],
                    [0, -2.13],
                    [-2.13, 0],
                  ],
                  o: [
                    [0, -2.13],
                    [-2.13, 0],
                    [0, 2.13],
                    [2.13, 0],
                  ],
                  v: [
                    [3.856, 0],
                    [0, -3.856],
                    [-3.856, 0],
                    [0, 3.856],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 38 },
                  { s: [100, 100], t: 42.0000017106951 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [142.522, 107.699], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 2.13],
                    [2.13, 0],
                    [0, -2.13],
                    [-2.13, 0],
                  ],
                  o: [
                    [0, -2.13],
                    [-2.13, 0],
                    [0, 2.13],
                    [2.13, 0],
                  ],
                  v: [
                    [3.856, 0],
                    [0, -3.856],
                    [-3.856, 0],
                    [0, 3.856],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0], t: 26 },
                  { s: [100, 100], t: 30.0000012219251 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [243.464, 189.078], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: false,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, -13.801],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [5.526, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [-50.802, -40.69],
                    [36.069, -40.69],
                    [50.802, -26.889],
                    [50.802, 40.69],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "tm",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Filter - Trim",
              nm: "Trim Paths 1",
              ix: 2,
              e: { a: 0, k: 100, ix: 2 },
              o: { a: 0, k: 0, ix: 3 },
              s: {
                a: 1,
                k: [
                  { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [100], t: 30 },
                  { s: [0], t: 40.0000016292334 },
                ],
                ix: 1,
              },
              m: 1,
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 10,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 3 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [192.662, 148.389], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 18,
    },
    {
      ty: 4,
      nm: "Layer 6",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [131.119, 111.991, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.33, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 40 },
            { s: [100, 100, 100], t: 60.0000024438501 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [131.119, 111.991, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -4.985],
                            [0, 0],
                            [-3.288, 3.249],
                            [-1.412, 0],
                            [0, 0],
                            [-1.005, -0.992],
                          ],
                          o: [
                            [0, 0],
                            [0, -4.985],
                            [1.005, -0.992],
                            [0, 0],
                            [1.412, 0],
                            [3.288, 3.249],
                          ],
                          v: [
                            [17.907, 7.118],
                            [-17.907, 7.118],
                            [-12.576, -5.623],
                            [-8.77, -7.118],
                            [8.77, -7.118],
                            [12.576, -5.623],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [84.565, 119.11], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -5.229],
                            [5.229, 0],
                            [0, 5.229],
                            [-5.229, 0],
                          ],
                          o: [
                            [0, 5.229],
                            [-5.229, 0],
                            [0, -5.229],
                            [5.229, 0],
                          ],
                          v: [
                            [9.468, 0],
                            [0, 9.468],
                            [-9.468, 0],
                            [0, -9.468],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [84.562, 99.355], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [84.562, 99.355], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [84.562, 99.355], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -20.548],
                        [20.548, 0],
                        [0, 20.548],
                        [-20.548, 0],
                      ],
                      o: [
                        [0, 20.548],
                        [-20.548, 0],
                        [0, -20.548],
                        [20.548, 0],
                      ],
                      v: [
                        [37.205, 0],
                        [0, 37.205],
                        [-37.205, 0],
                        [0, -37.205],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [84.564, 111.991], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [84.564, 111.991], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.333, y: 0 },
                    i: { x: 0.667, y: 1 },
                    s: [0, 0],
                    t: 50,
                  },
                  { s: [100, 100], t: 65.0000026475043 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [84.564, 111.991], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [3.075, -1.838],
                        [1.838, -3.075],
                        [0, -1.237],
                        [-1.838, -3.075],
                        [-3.075, -1.838],
                        [-1.237, 0],
                        [-3.075, 1.838],
                        [-1.838, 3.075],
                        [0, 1.237],
                        [1.838, 3.075],
                        [3.075, 1.838],
                        [1.237, 0],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [122.114, 75.184], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.02, -2.769],
                        [2.769, -0.02],
                        [0.02, 2.769],
                        [-2.769, 0.02],
                      ],
                      o: [
                        [0.02, 2.769],
                        [-2.768, 0.02],
                        [-0.02, -2.769],
                        [2.769, -0.02],
                      ],
                      v: [
                        [5.013, -0.037],
                        [0.037, 5.013],
                        [-5.013, 0.037],
                        [-0.037, -5.013],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [122.114, 75.184], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [122.114, 75.184], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [122.114, 75.184], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [6.09, 0],
                    [0, 0],
                    [0, 6.09],
                    [0, 0],
                    [-6.09, 0],
                    [0, 0],
                    [0, -6.09],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-6.09, 0],
                    [0, 0],
                    [0, -6.09],
                    [0, 0],
                    [6.09, 0],
                    [0, 0],
                    [0, 6.09],
                  ],
                  v: [
                    [35.528, 46.555],
                    [-35.528, 46.555],
                    [-46.555, 35.528],
                    [-46.555, -35.528],
                    [-35.528, -46.555],
                    [35.528, -46.555],
                    [46.555, -35.528],
                    [46.555, 35.528],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [84.564, 111.991], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 19,
    },
    {
      ty: 4,
      nm: "UI/UX Outlines",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [88.341, -21.436, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 36 },
            { s: [100, 100, 100], t: 61.0000024845809 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [372.079, 133.818, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "U",
          ix: 1,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "U",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-11.498, 0],
                    [0, 11.903],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [5.72, 0],
                    [0, 7.685],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [11.498, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 7.685],
                    [-5.662, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 11.903],
                  ],
                  v: [
                    [22.765, 0.693],
                    [41.081, -17.796],
                    [41.081, -40.446],
                    [31.836, -40.446],
                    [31.836, -18.143],
                    [22.823, -7.28],
                    [13.809, -18.143],
                    [13.809, -40.446],
                    [4.449, -40.446],
                    [4.449, -17.796],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "I",
          ix: 2,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "I",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [50.005, 0],
                    [59.365, 0],
                    [59.365, -40.446],
                    [50.005, -40.446],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "/",
          ix: 3,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "/",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [62.12, 5.778],
                    [70.151, 5.778],
                    [89.219, -48.65],
                    [81.187, -48.65],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "U",
          ix: 4,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "U",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [-11.498, 0],
                    [0, 11.903],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [5.72, 0],
                    [0, 7.685],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [11.498, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 7.685],
                    [-5.662, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 11.903],
                  ],
                  v: [
                    [109.282, 0.693],
                    [127.598, -17.796],
                    [127.598, -40.446],
                    [118.353, -40.446],
                    [118.353, -18.143],
                    [109.34, -7.28],
                    [100.326, -18.143],
                    [100.326, -40.446],
                    [90.966, -40.446],
                    [90.966, -17.796],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "X",
          ix: 5,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "X",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [172.234, 0],
                    [157.153, -20.916],
                    [171.251, -40.446],
                    [161.14, -40.446],
                    [152.011, -27.33],
                    [142.709, -40.446],
                    [132.077, -40.446],
                    [146.291, -20.57],
                    [131.326, 0],
                    [142.073, 0],
                    [151.664, -14.04],
                    [161.429, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "st",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Stroke",
              nm: "Stroke 1",
              lc: 1,
              lj: 1,
              ml: 4,
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              c: { a: 0, k: [0, 0, 0], ix: 3 },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [0, 0], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 20,
      parent: 21,
    },
    {
      ty: 4,
      nm: "Layer 27",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [369.46, 218.075, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              o: { x: 0.333, y: 0 },
              i: { x: 0.667, y: 1 },
              s: [0, 0, 100],
              t: 21,
            },
            { s: [100, 100, 100], t: 41.0000016699642 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [369.46, 218.075, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.851, 0],
                    [0, 0],
                    [0, 0.851],
                    [0, 0],
                    [-0.851, 0],
                    [0, 0],
                    [0, -0.851],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.851, 0],
                    [0, 0],
                    [0, -0.851],
                    [0, 0],
                    [0.851, 0],
                    [0, 0],
                    [0, 0.851],
                  ],
                  v: [
                    [82.431, 1.541],
                    [-82.431, 1.541],
                    [-83.972, 0],
                    [-83.972, 0],
                    [-82.431, -1.541],
                    [82.431, -1.541],
                    [83.972, 0],
                    [83.972, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.33, y: 0 },
                    i: { x: 0, y: 1 },
                    s: [0, 100],
                    t: 40,
                  },
                  { s: [100, 100], t: 65.0000026475043 },
                ],
                ix: 3,
              },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [371.217, 175.806], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 1,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [5.041, 0],
                        [0, 0],
                        [0, 5.041],
                        [0, 0],
                        [-5.041, 0],
                        [0, 0],
                        [0, -5.041],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-5.041, 0],
                        [0, 0],
                        [0, -5.041],
                        [0, 0],
                        [5.041, 0],
                        [0, 0],
                        [0, 5.041],
                      ],
                      v: [
                        [15.639, 9.127],
                        [-15.639, 9.127],
                        [-24.766, 0],
                        [-24.766, 0],
                        [-15.639, -9.127],
                        [15.639, -9.127],
                        [24.766, 0],
                        [24.766, 0],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0, y: 1 },
                        s: [0, 0],
                        t: 44,
                      },
                      { s: [100, 100], t: 69.0000028104276 },
                    ],
                    ix: 3,
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [0, 0], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [369.46, 330.231], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [1.091, 0],
                            [0, 0],
                            [0, 1.091],
                            [0, 0],
                            [-1.091, 0],
                            [0, 0],
                            [0, -1.091],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.091, 0],
                            [0, 0],
                            [0, -1.091],
                            [0, 0],
                            [1.091, 0],
                            [0, 0],
                            [0, 1.091],
                          ],
                          v: [
                            [77.108, 2.13],
                            [-77.108, 2.13],
                            [-79.083, 0.155],
                            [-79.083, -0.155],
                            [-77.108, -2.13],
                            [77.108, -2.13],
                            [79.083, -0.155],
                            [79.083, 0.155],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [370.884, 214.588], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [1.254, 0],
                            [0, 0],
                            [0, 1.254],
                            [0, 0],
                            [-1.254, 0],
                            [0, 0],
                            [0, -1.254],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.254, 0],
                            [0, 0],
                            [0, -1.254],
                            [0, 0],
                            [1.254, 0],
                            [0, 0],
                            [0, 1.254],
                          ],
                          v: [
                            [38.654, 2.271],
                            [-38.654, 2.271],
                            [-40.925, 0],
                            [-40.925, 0],
                            [-38.654, -2.271],
                            [38.654, -2.271],
                            [40.925, 0],
                            [40.925, 0],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: { a: 0, k: [100, 100], ix: 3 },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [332.725, 204.55], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [290.725, 209.55], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0, y: 1 },
                        s: [0, 100],
                        t: 50,
                      },
                      { s: [100, 100], t: 75.0000030548126 },
                    ],
                    ix: 3,
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [290.725, 209.55], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [2.533, 0],
                        [0, 0],
                        [0, 2.533],
                        [0, 0],
                        [-2.534, 0],
                        [0, 0],
                        [0, -2.533],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-2.534, 0],
                        [0, 0],
                        [0, -2.533],
                        [0, 0],
                        [2.533, 0],
                        [0, 0],
                        [0, 2.533],
                      ],
                      v: [
                        [79.385, 13.032],
                        [-79.385, 13.032],
                        [-83.972, 8.445],
                        [-83.972, -8.445],
                        [-79.385, -13.032],
                        [79.385, -13.032],
                        [83.972, -8.445],
                        [83.972, 8.445],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0, y: 1 },
                        s: [100, 0],
                        t: 44,
                      },
                      { s: [100, 100], t: 69.0000028104276 },
                    ],
                    ix: 3,
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [370.884, 209.499], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [370.884, 209.499], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [370.884, 209.499], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          ix: 4,
          cix: 2,
          np: 4,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 6",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [1.174, 0],
                            [0, 0],
                            [0, 1.174],
                            [0, 0],
                            [-1.174, 0],
                            [0, 0],
                            [0, -1.174],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.174, 0],
                            [0, 0],
                            [0, -1.174],
                            [0, 0],
                            [1.174, 0],
                            [0, 0],
                            [0, 1.174],
                          ],
                          v: [
                            [61.625, 2.125],
                            [-61.625, 2.125],
                            [-63.75, 0],
                            [-63.75, 0],
                            [-61.625, -2.125],
                            [61.625, -2.125],
                            [63.75, 0],
                            [63.75, 0],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [-64, -0.25], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0, y: 1 },
                            s: [0, 100],
                            t: 49,
                          },
                          { s: [100, 100], t: 74.0000030140818 },
                        ],
                        ix: 3,
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [318.133, 294.568], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -4.51],
                            [4.51, 0],
                            [0, 4.51],
                            [-4.51, 0],
                          ],
                          o: [
                            [0, 4.51],
                            [-4.51, 0],
                            [0, -4.51],
                            [4.51, 0],
                          ],
                          v: [
                            [8.167, 0],
                            [0, 8.167],
                            [-8.167, 0],
                            [0, -8.167],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0, y: 1 },
                            s: [0, 0],
                            t: 45,
                          },
                          { s: [100, 100], t: 70.0000028511585 },
                        ],
                        ix: 3,
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [304.05, 294.818], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [304.05, 294.818], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [304.05, 254.318], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 5",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [1.174, 0],
                            [0, 0],
                            [0, 1.174],
                            [0, 0],
                            [-1.174, 0],
                            [0, 0],
                            [0, -1.174],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.174, 0],
                            [0, 0],
                            [0, -1.174],
                            [0, 0],
                            [1.174, 0],
                            [0, 0],
                            [0, 1.174],
                          ],
                          v: [
                            [61.625, 2.125],
                            [-61.625, 2.125],
                            [-63.75, 0],
                            [-63.75, 0],
                            [-61.625, -2.125],
                            [61.625, -2.125],
                            [63.75, 0],
                            [63.75, 0],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [-64, -0.25], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0, y: 1 },
                            s: [0, 100],
                            t: 51,
                          },
                          { s: [100, 100], t: 76.0000030955435 },
                        ],
                        ix: 3,
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [318.133, 294.568], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -4.51],
                            [4.51, 0],
                            [0, 4.51],
                            [-4.51, 0],
                          ],
                          o: [
                            [0, 4.51],
                            [-4.51, 0],
                            [0, -4.51],
                            [4.51, 0],
                          ],
                          v: [
                            [8.167, 0],
                            [0, 8.167],
                            [-8.167, 0],
                            [0, -8.167],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0, y: 1 },
                            s: [0, 0],
                            t: 45,
                          },
                          { s: [100, 100], t: 70.0000028511585 },
                        ],
                        ix: 3,
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [304.05, 294.818], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [304.05, 294.818], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [304.05, 274.318], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 1",
                  ix: 1,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [1.174, 0],
                            [0, 0],
                            [0, 1.174],
                            [0, 0],
                            [-1.174, 0],
                            [0, 0],
                            [0, -1.174],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [-1.174, 0],
                            [0, 0],
                            [0, -1.174],
                            [0, 0],
                            [1.174, 0],
                            [0, 0],
                            [0, 1.174],
                          ],
                          v: [
                            [61.625, 2.125],
                            [-61.625, 2.125],
                            [-63.75, 0],
                            [-63.75, 0],
                            [-61.625, -2.125],
                            [61.625, -2.125],
                            [63.75, 0],
                            [63.75, 0],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [-64, -0.25], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0, y: 1 },
                            s: [0, 100],
                            t: 52,
                          },
                          { s: [100, 100], t: 77.0000031362743 },
                        ],
                        ix: 3,
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [318.133, 294.568], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "gr",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Group",
                  nm: "Group 2",
                  ix: 2,
                  cix: 2,
                  np: 2,
                  it: [
                    {
                      ty: "sh",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Shape - Group",
                      nm: "Path 1",
                      ix: 1,
                      d: 1,
                      ks: {
                        a: 0,
                        k: {
                          c: true,
                          i: [
                            [0, -4.51],
                            [4.51, 0],
                            [0, 4.51],
                            [-4.51, 0],
                          ],
                          o: [
                            [0, 4.51],
                            [-4.51, 0],
                            [0, -4.51],
                            [4.51, 0],
                          ],
                          v: [
                            [8.167, 0],
                            [0, 8.167],
                            [-8.167, 0],
                            [0, -8.167],
                          ],
                        },
                        ix: 2,
                      },
                    },
                    {
                      ty: "fl",
                      bm: 0,
                      hd: false,
                      mn: "ADBE Vector Graphic - Fill",
                      nm: "Fill 1",
                      c: { a: 0, k: [1, 1, 1], ix: 4 },
                      r: 1,
                      o: { a: 0, k: 100, ix: 5 },
                    },
                    {
                      ty: "tr",
                      a: { a: 0, k: [0, 0], ix: 1 },
                      s: {
                        a: 1,
                        k: [
                          {
                            o: { x: 0.333, y: 0 },
                            i: { x: 0, y: 1 },
                            s: [0, 0],
                            t: 45,
                          },
                          { s: [100, 100], t: 70.0000028511585 },
                        ],
                        ix: 3,
                      },
                      sk: { a: 0, k: 0, ix: 4 },
                      p: { a: 0, k: [304.05, 294.818], ix: 2 },
                      r: { a: 0, k: 0, ix: 6 },
                      sa: { a: 0, k: 0, ix: 5 },
                      o: { a: 0, k: 100, ix: 7 },
                    },
                  ],
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [304.05, 294.818], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [304.05, 294.818], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 4",
              ix: 4,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [4.112, 0],
                        [0, 0],
                        [0, 4.112],
                        [0, 0],
                        [-4.112, 0],
                        [0, 0],
                        [0, -4.112],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-4.112, 0],
                        [0, 0],
                        [0, -4.112],
                        [0, 0],
                        [4.112, 0],
                        [0, 0],
                        [0, 4.112],
                      ],
                      v: [
                        [76.526, 34.333],
                        [-76.526, 34.333],
                        [-83.972, 26.888],
                        [-83.972, -26.888],
                        [-76.526, -34.333],
                        [76.526, -34.333],
                        [83.972, -26.888],
                        [83.972, 26.888],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: {
                    a: 1,
                    k: [
                      {
                        o: { x: 0.333, y: 0 },
                        i: { x: 0, y: 1 },
                        s: [100, 0],
                        t: 40,
                      },
                      { s: [100, 100], t: 65.0000026475043 },
                    ],
                    ix: 3,
                  },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [370.884, 274.558], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [370.884, 274.558], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [370.884, 274.558], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          ix: 5,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [4.47, -2.671],
                        [2.671, -4.469],
                        [0, -1.798],
                        [-2.671, -4.469],
                        [-4.47, -2.671],
                        [-1.798, 0],
                        [-4.47, 2.671],
                        [-2.671, 4.469],
                        [0, 1.798],
                        [2.671, 4.469],
                        [4.47, 2.671],
                        [1.798, 0],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [464.376, 83.269], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [-0.03, -4.024],
                        [4.024, -0.029],
                        [0.029, 4.024],
                        [-4.024, 0.029],
                      ],
                      o: [
                        [0.029, 4.024],
                        [-4.024, 0.029],
                        [-0.029, -4.024],
                        [4.024, -0.029],
                      ],
                      v: [
                        [7.286, -0.053],
                        [0.053, 7.286],
                        [-7.286, 0.053],
                        [-0.053, -7.286],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [464.376, 83.269], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [464.376, 83.269], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [464.376, 83.269], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          ix: 6,
          cix: 2,
          np: 3,
          it: [
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 1",
              ix: 1,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -1.289],
                        [1.289, 0],
                        [0, 1.289],
                        [-1.289, 0],
                      ],
                      o: [
                        [0, 1.289],
                        [-1.289, 0],
                        [0, -1.289],
                        [1.289, 0],
                      ],
                      v: [
                        [2.333, 0],
                        [0, 2.333],
                        [-2.333, 0],
                        [0, -2.333],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [379.63, 358.755], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 2",
              ix: 2,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -1.289],
                        [1.289, 0],
                        [0, 1.289],
                        [-1.289, 0],
                      ],
                      o: [
                        [0, 1.289],
                        [-1.289, 0],
                        [0, -1.289],
                        [1.289, 0],
                      ],
                      v: [
                        [2.333, 0],
                        [0, 2.333],
                        [-2.333, 0],
                        [0, -2.333],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [369.463, 358.755], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "gr",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Group",
              nm: "Group 3",
              ix: 3,
              cix: 2,
              np: 2,
              it: [
                {
                  ty: "sh",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Shape - Group",
                  nm: "Path 1",
                  ix: 1,
                  d: 1,
                  ks: {
                    a: 0,
                    k: {
                      c: true,
                      i: [
                        [0, -1.289],
                        [1.289, 0],
                        [0, 1.289],
                        [-1.289, 0],
                      ],
                      o: [
                        [0, 1.289],
                        [-1.289, 0],
                        [0, -1.289],
                        [1.289, 0],
                      ],
                      v: [
                        [2.333, 0],
                        [0, 2.333],
                        [-2.333, 0],
                        [0, -2.333],
                      ],
                    },
                    ix: 2,
                  },
                },
                {
                  ty: "fl",
                  bm: 0,
                  hd: false,
                  mn: "ADBE Vector Graphic - Fill",
                  nm: "Fill 1",
                  c: { a: 0, k: [1, 1, 1], ix: 4 },
                  r: 1,
                  o: { a: 0, k: 100, ix: 5 },
                },
                {
                  ty: "tr",
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  sk: { a: 0, k: 0, ix: 4 },
                  p: { a: 0, k: [359.297, 358.755], ix: 2 },
                  r: { a: 0, k: 0, ix: 6 },
                  sa: { a: 0, k: 0, ix: 5 },
                  o: { a: 0, k: 100, ix: 7 },
                },
              ],
            },
            {
              ty: "tr",
              a: { a: 0, k: [359.297, 358.755], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [359.297, 358.755], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          ix: 7,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [-7.22, 0],
                    [0, 0],
                    [0, 7.22],
                  ],
                  o: [
                    [0, 7.22],
                    [0, 0],
                    [7.22, 0],
                    [0, 0],
                  ],
                  v: [
                    [-106.94, -6.535],
                    [-93.87, 6.535],
                    [93.87, 6.535],
                    [106.94, -6.535],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [369.46, 358.755], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          ix: 8,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, -7.22],
                    [0, 0],
                    [7.22, 0],
                    [0, 0],
                    [0, 7.22],
                    [0, 0],
                    [-7.22, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 7.22],
                    [0, 0],
                    [-7.22, 0],
                    [0, 0],
                    [0, -7.22],
                    [0, 0],
                    [7.22, 0],
                  ],
                  v: [
                    [106.94, -134.145],
                    [106.94, 134.145],
                    [93.87, 147.215],
                    [-93.87, 147.215],
                    [-106.94, 134.145],
                    [-106.94, -134.145],
                    [-93.87, -147.215],
                    [93.87, -147.215],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2824, 0.6235, 0.7176], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [369.46, 218.075], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 21,
    },
    {
      ty: 4,
      nm: "Layer 16",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [375, 453.471, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { o: { x: 0.333, y: 0 }, i: { x: 0, y: 1 }, s: [0, 0, 100], t: 0 },
            { s: [100, 100, 100], t: 20.0000008146167 },
          ],
          ix: 6,
        },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [375, 453.471, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      shapes: [
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 1",
          ix: 1,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [11.02, 0],
                    [0, 0],
                    [0, 11.02],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-11.02, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 11.02],
                  ],
                  v: [
                    [221.793, 11.929],
                    [-221.793, 11.929],
                    [-241.747, -8.025],
                    [-241.747, -11.929],
                    [241.747, -11.929],
                    [241.747, -8.025],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2745, 0.2745, 0.2745], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [375, 441.541], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 2",
          ix: 2,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.792, 0],
                    [0, 0],
                    [0, 0.792],
                    [0, 0],
                    [-0.792, 0],
                    [0, 0],
                    [0, -0.792],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.792, 0],
                    [0, 0],
                    [0, -0.792],
                    [0, 0],
                    [0.792, 0],
                    [0, 0],
                    [0, 0.792],
                  ],
                  v: [
                    [30.025, 1.434],
                    [-30.025, 1.434],
                    [-31.459, 0],
                    [-31.459, 0],
                    [-30.025, -1.434],
                    [30.025, -1.434],
                    [31.459, 0],
                    [31.459, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [213.126, 234.809], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 3",
          ix: 3,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0.792, 0],
                    [0, 0],
                    [0, 0.792],
                    [0, 0],
                    [-0.792, 0],
                    [0, 0],
                    [0, -0.792],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-0.792, 0],
                    [0, 0],
                    [0, -0.792],
                    [0, 0],
                    [0.792, 0],
                    [0, 0],
                    [0, 0.792],
                  ],
                  v: [
                    [19.78, 1.434],
                    [-19.78, 1.434],
                    [-21.214, 0],
                    [-21.214, 0],
                    [-19.78, -1.434],
                    [19.78, -1.434],
                    [21.214, 0],
                    [21.214, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [202.881, 242.242], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 4",
          ix: 4,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.955, 0],
                    [0, 0],
                    [0, 2.955],
                    [0, 0],
                    [-2.955, 0],
                    [0, 0],
                    [0, -2.955],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.955, 0],
                    [0, 0],
                    [0, -2.955],
                    [0, 0],
                    [2.955, 0],
                    [0, 0],
                    [0, 2.955],
                  ],
                  v: [
                    [17.092, 20.592],
                    [-17.092, 20.592],
                    [-22.443, 15.241],
                    [-22.443, -15.241],
                    [-17.092, -20.592],
                    [17.092, -20.592],
                    [22.443, -15.241],
                    [22.443, 15.241],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [204.11, 205.634], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 9,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 5",
          ix: 5,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.012, 0],
                    [0, 0],
                    [0, 3.012],
                    [0, 0],
                    [-3.012, 0],
                    [0, 0],
                    [0, -3.012],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.012, 0],
                    [0, 0],
                    [0, -3.012],
                    [0, 0],
                    [3.012, 0],
                    [0, 0],
                    [0, 3.012],
                  ],
                  v: [
                    [43.744, 20.157],
                    [-43.745, 20.157],
                    [-49.197, 14.704],
                    [-49.197, -14.704],
                    [-43.745, -20.157],
                    [43.744, -20.157],
                    [49.197, -14.704],
                    [49.197, 14.704],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [487.697, 214.383], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 9,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 6",
          ix: 6,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.445, 0],
                    [0, 0],
                    [0, 1.446],
                    [0, 0],
                    [-1.445, 0],
                    [0, 0],
                    [0, -1.445],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.445, 0],
                    [0, 0],
                    [0, -1.445],
                    [0, 0],
                    [1.445, 0],
                    [0, 0],
                    [0, 1.446],
                  ],
                  v: [
                    [57.071, 2.617],
                    [-57.071, 2.617],
                    [-59.688, 0],
                    [-59.688, 0],
                    [-57.071, -2.617],
                    [57.071, -2.617],
                    [59.688, 0],
                    [59.688, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [401.688, 392.48], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 9,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 7",
          ix: 7,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.446, 0],
                    [0, 0],
                    [0, 1.446],
                    [0, 0],
                    [-1.445, 0],
                    [0, 0],
                    [0, -1.446],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.445, 0],
                    [0, 0],
                    [0, -1.446],
                    [0, 0],
                    [1.446, 0],
                    [0, 0],
                    [0, 1.446],
                  ],
                  v: [
                    [57.071, 2.617],
                    [-57.071, 2.617],
                    [-59.688, 0],
                    [-59.688, 0],
                    [-57.071, -2.617],
                    [57.071, -2.617],
                    [59.688, 0],
                    [59.688, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [401.688, 378.742], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 8",
          ix: 8,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [124.68, 116.591],
                    [-124.68, 116.591],
                    [-124.68, -116.591],
                    [124.68, -116.591],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [452.25, 288.317], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 9",
          ix: 9,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [3.359, 0],
                    [0, 0],
                    [0, 3.359],
                    [0, 0],
                    [-3.359, 0],
                    [0, 0],
                    [0, -3.359],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.359, 0],
                    [0, 0],
                    [0, -3.359],
                    [0, 0],
                    [3.359, 0],
                    [0, 0],
                    [0, 3.359],
                  ],
                  v: [
                    [37.02, 28.628],
                    [-37.02, 28.628],
                    [-43.102, 22.545],
                    [-43.102, -22.545],
                    [-37.02, -28.628],
                    [37.02, -28.628],
                    [43.102, -22.545],
                    [43.102, 22.545],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.5294, 0.5255, 0.5294], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [271.808, 349.732], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 10",
          ix: 10,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.342, 0],
                    [0, 0],
                    [0, 2.342],
                    [0, 0],
                    [-2.342, 0],
                    [0, 0],
                    [0, -2.342],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.342, 0],
                    [0, 0],
                    [0, -2.342],
                    [0, 0],
                    [2.342, 0],
                    [0, 0],
                    [0, 2.342],
                  ],
                  v: [
                    [199.093, 116.591],
                    [-199.093, 116.591],
                    [-203.333, 112.351],
                    [-203.333, -112.351],
                    [-199.093, -116.591],
                    [199.093, -116.591],
                    [203.333, -112.351],
                    [203.333, 112.351],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [373.597, 288.317], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 11",
          ix: 11,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [2.676, 0],
                    [0, 0],
                    [0, 2.676],
                    [0, 0],
                    [-2.676, 0],
                    [0, 0],
                    [0, -2.676],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.676, 0],
                    [0, 0],
                    [0, -2.676],
                    [0, 0],
                    [2.676, 0],
                    [0, 0],
                    [0, 2.676],
                  ],
                  v: [
                    [202.579, 120.266],
                    [-202.579, 120.266],
                    [-207.424, 115.421],
                    [-207.424, -115.421],
                    [-202.579, -120.266],
                    [202.579, -120.266],
                    [207.424, -115.421],
                    [207.424, 115.421],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.2745, 0.2745, 0.2745], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [373.597, 288.062], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 9,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 12",
          ix: 12,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [1.588, 0],
                    [0, 0],
                    [0, 1.588],
                    [0, 0],
                    [-1.588, 0],
                    [0, 0],
                    [0, -1.588],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-1.588, 0],
                    [0, 0],
                    [0, -1.588],
                    [0, 0],
                    [1.588, 0],
                    [0, 0],
                    [0, 1.588],
                  ],
                  v: [
                    [68.125, 2.875],
                    [-68.125, 2.875],
                    [-71, 0],
                    [-71, 0],
                    [-68.125, -2.875],
                    [68.125, -2.875],
                    [71, 0],
                    [71, 0],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [1, 1, 1], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [373.596, 418.5], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
        {
          ty: "gr",
          bm: 0,
          hd: false,
          mn: "ADBE Vector Group",
          nm: "Group 13",
          ix: 13,
          cix: 2,
          np: 2,
          it: [
            {
              ty: "sh",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Shape - Group",
              nm: "Path 1",
              ix: 1,
              d: 1,
              ks: {
                a: 0,
                k: {
                  c: true,
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-8.709, 0],
                    [0, 0],
                    [0, -8.709],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -8.709],
                    [0, 0],
                    [8.709, 0],
                    [0, 0],
                  ],
                  v: [
                    [214.73, 133.68],
                    [-214.73, 133.68],
                    [-214.73, -117.912],
                    [-198.962, -133.68],
                    [198.962, -133.68],
                    [214.73, -117.912],
                  ],
                },
                ix: 2,
              },
            },
            {
              ty: "fl",
              bm: 0,
              hd: false,
              mn: "ADBE Vector Graphic - Fill",
              nm: "Fill 1",
              c: { a: 0, k: [0.1686, 0.1569, 0.1608], ix: 4 },
              r: 1,
              o: { a: 0, k: 100, ix: 5 },
            },
            {
              ty: "tr",
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              sk: { a: 0, k: 0, ix: 4 },
              p: { a: 0, k: [373.597, 295.932], ix: 2 },
              r: { a: 0, k: 0, ix: 6 },
              sa: { a: 0, k: 0, ix: 5 },
              o: { a: 0, k: 100, ix: 7 },
            },
          ],
        },
      ],
      ind: 22,
    },
    {
      ty: 3,
      nm: "",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [375, 476.735, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      ind: 23,
    },
    {
      ty: 3,
      nm: "",
      sr: 1,
      st: 0,
      op: 300.00001221925,
      ip: 0,
      hd: false,
      ddd: 0,
      bm: 0,
      hasMask: false,
      ao: 0,
      ks: {
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
        sk: { a: 0, k: 0 },
        p: { a: 0, k: [375, 250, 0], ix: 2 },
        r: { a: 0, k: 0, ix: 10 },
        sa: { a: 0, k: 0 },
        o: { a: 0, k: 100, ix: 11 },
      },
      ef: [],
      ind: 24,
    },
  ],
  v: "5.8.0",
  fr: 29.9700012207031,
  op: 151.000006150356,
  ip: 0,
  assets: [],
};
